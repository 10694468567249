(function ($) {


const Init = (() => {

  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      form         = {},
      wp           = {},
      favorite     = {},
      imgSwitch    = {},
      loader       = {};

  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,

    headTag       : $('.l-header'),

    act           : 'is-act',
    actOpen       : 'is-open',

    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;

  load = {
    functions: [],
    length   : 0,
  };

  resize = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };

  scroll = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };


  const WinLoad = {

    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });

      console.log('!-- load init --!');
    },

    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },

    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },

    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },

  }


  const WinResize = {

    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;

          resize.animationFrame = window.requestAnimationFrame( WinResize.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinResize.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinResize.update();
          //   }, 1000/resize.fps);
          // }
        }
      });

      console.log('!-- resize init --!');
    },

    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },

    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },

    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();

      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }

      resize.isRunning = false;
    },

  }


  const WinScroll = {

    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;

          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinScroll.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinScroll.update();
          //   }, 1000/scroll.fps);
          // }
        }
      });

      console.log('!-- scroll init --!');
    },

    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },

    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },

    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;

      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }

      scroll.isRunning = false;
    },

  }


  const Modules = {

    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        smoothSpeed     : 600,

        targetTab       : 'js-tabWrap',
        targetTabTrigger: 'js-tabTrg',
        targetTabContent: 'js-tabCont',

        targetAccordion : 'js-accordion',

        targetModal : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',

        targetAlign     : 'js-align',
        targetAlignList : [],
        alignAddClass   : 'c-alignheight',

        targetOfi       : '.js-ofi .ofi',

        targetMenu : 'js-menu',
        targetPagenavi  : 'js-pagenavi',


        targetMegaTrg   : 'js-mega_trg',
        targetMegaCont  : 'js-mega_body',

      };

      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);

      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      this.tabBtn();
      this.accordionBtn();
      this.modalBtn();
      this.megamenu();

      objectFitImages(mod.targetOfi);
    },

    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },

    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },

    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            shift,position;
            if($('.'+mod.targetMenu).length){
              shift    = ( (href != "#") ) ? $('.'+mod.targetMenu).outerHeight() : 0;
            }else{
              shift    = 0;
            }
            position = target.offset().top - shift
        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },

    tabBtn() {
      $('.' + mod.targetTabTrigger).each(function(){
        let tabBtn = $(this).children('li').children('a');
        tabBtn.on('click',function(){
          let tabHref = $(this).attr('href'),
              tabObj = $(this).parent('li').siblings('li').children('a'),
              targetCont = $(this).closest('.'+mod.targetTab).find('.'+mod.targetTabContent),
              targetObj = targetCont.find(tabHref),
              contsObj = targetObj.siblings();
              tabObj.removeClass('is-act');
              $(this).addClass('is-act');
              contsObj.hide();
              targetObj.fadeIn();
              return false;
        });
        $(this).children('li').eq(0).children('a').click();
      });
    },

    accordionBtn() {
      $('.' + mod.targetAccordion).on('click', function(){
        if ( $(this).hasClass(website.act) ) {
          $(this).removeClass(website.act).next().stop().slideUp('fast');
          // $(this).removeClass(website.act);
        } else {
          $(this).addClass(website.act).next().stop().slideDown();
          // $(this).addClass(website.act);
        }
      });
    },

    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },

    alignHeight() {
      let alignHeight  = 0;

      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = 'auto';
        target.classList.add(mod.alignAddClass);
        alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;
        target.classList.remove(mod.alignAddClass);
      });
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = alignHeight + 'px';
      });
    },

    fixedMenu() {
      let triggerPosition = 100;
      if(website.scrollY > triggerPosition){
        $('.'+mod.targetMenu).addClass('is-move');
      }else{
        $('.'+mod.targetMenu).removeClass('is-move');
      }
    },

    megamenu(){
      if($('.'+mod.targetMegaTrg).length){
        $('.'+mod.targetMegaTrg).hover(
          function(){
            $(this).children('.'+mod.targetMegaCont).stop().slideDown();
          },
          function(){
            $(this).children('.'+mod.targetMegaCont).stop().slideUp();
          }
        );
      }
    },
  }


  // const Loader = {
  //   init() {
  //     loader = {
  //       targetClass : 'js-loader',
  //       initClass    : 'is-load',
  //       actClass    : 'is-move',
  //     };
  //     if($('.'+loader.targetClass).length){
  //       $('.'+loader.targetClass).addClass(loader.initClass);
  //     }
  //   },

  //   set() {
  //     if($('.'+loader.targetClass).length){
  //       $('.'+loader.targetClass).addClass(loader.actClass);
  //     }
  //   },
  // }

  const Animation = {

    init() {
    },

    check() {
      $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
        let self = $(this),
            targetPosition = self.offset().top,
            triggerPosition,
            shift;

        if( website.winWidth < website.shiftPoint ) {
          shift = website.winHeight / website.shiftSp;
        } else {
          shift = website.winHeight / website.shiftPc;
        }
        triggerPosition = targetPosition - website.winHeight + shift;

        if( website.scrollY > triggerPosition ){
          self.addClass(website.actAnime);
        }
      });
    },

  }


  // const PageScroll = {

  //   init() {
  //     scrollOption = {
  //       target    : document.getElementById('js-contbox'),
  //       translateY: 0,
  //       contHeight: 0,
  //       velocity  : 0.08,

  //       rootMargin : '0px',
  //       threshold  : 0.1,
  //     };

  //     this.observer();
  //   },

  //   set() {
  //     scrollOption.contHeight    = scrollOption.target.getBoundingClientRect().height - 1;
  //     document.body.style.height = Math.floor(scrollOption.contHeight) + 'px';
  //   },

  //   smooth() {
  //     this.set();

  //     let diff = website.scrollY - scrollOption.translateY;

  //     if ( diff !== 0 ) {
  //       if ( Math.abs(diff) > 1 ) {
  //         scrollOption.translateY += diff * scrollOption.velocity;
  //       } else {
  //         scrollOption.translateY = website.scrollY;
  //       }
  //       scrollOption.target.style.transform = 'translate3d(0, -' + scrollOption.translateY + 'px, 0)';
  //     }
  //   },

  //   observer() {
  //     let targets = Array.from( document.querySelectorAll('.' + website.targetAnime) ),
  //         options = {
  //           rootMargin : scrollOption.rootMargin,
  //           threshold  : scrollOption.threshold,
  //         };
  //     let observer = new IntersectionObserver(this.observerCallback, options);

  //     targets.forEach((target) => {
  //       if ( target.dataset.delay ) {
  //         target.style.transitionDelay = target.dataset.delay + 'ms';
  //       }

  //       observer.observe(target);
  //     });
  //   },

  //   observerCallback(entries, object) {
  //     entries.forEach(function(entry, i) {
  //       if (!entry.isIntersecting) return;
  //       entry.target.classList.add(website.actAnime);
  //       object.unobserve(entry.target);
  //     });
  //   },
  // }


  // const Parallax = {

  //   init() {
  //     parallax = {
  //       nodeList   : document.querySelectorAll('.js-parallax'),
  //       gap        : 0.08,

  //       decoList   : [],
  //     };

  //     let targets = Array.prototype.slice.call(parallax.nodeList, 0);
  //     targets.forEach((target) => {
  //       if ( target.dataset.para ) {
  //         let arr = target.dataset.para.split(',');
  //         parallax.decoList.push( [target, arr] );
  //       }
  //     });
  //   },

  //   decoImg() {
  //     parallax.decoList.forEach((target) => {
  //       let targetRect = target[0].getBoundingClientRect(),
  //           parentNode = document.querySelector(target[1][0]),
  //           parentRect = parentNode.getBoundingClientRect(),
  //           diff       = targetRect.top - parentRect.top,
  //           gap        = (target[1][3]) ? Number(target[1][3]): parallax.gap,
  //           translateX,
  //           translateY;

  //       if ( (targetRect.top - website.winHeight) <= 0 ) {
  //         if ( target[1][1] == 'X' ) {
  //           translateX = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(' + translateX + 'px, 0px, 0px)';
  //         } else {
  //           translateY = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(0, ' + translateY + 'px, 0)';
  //         }

  //       }
  //     });
  //   },

  // }


  const DrawerMenu = {

    init() {
      drawer = {
        panel     : 'js-so_panel',
        menu      : 'js-so_menu',
        duration  : 10,
        // openclass : 'slideout-open',
        toggleList: '#js-so_toggle',
        closeList : '#js-so_panel, #js-so_menu .js-scroll',
      };

      this.menu();
    },

    menu() {
      let slideoutInst = new Slideout({
        'panel': document.getElementById(drawer.panel),
        'menu': document.getElementById(drawer.menu),
        'duration': drawer.duration,
        // 'openclass': drawer.openclass,
      });

      let nodeList = document.querySelectorAll(drawer.toggleList);
      let node = Array.prototype.slice.call(nodeList,0);
      node.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.toggle();
        });
      });

      let nodeListClose = document.querySelectorAll(drawer.closeList);
      let nodeClose = Array.prototype.slice.call(nodeListClose,0);
      nodeClose.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.close();
        });
      });
    },

  }


  const Wordpress = {

    init() {
      wp = {
        editorTarget: 'js-editor',
        tableClass  : 'c-rwdsheet',
        tableInnerClass  : 'c-rwdsheet__inner',
        iframeclass : 'c-iframe',
      }

      this.wpEditor();
    },

    wpEditor() {
      if ( $('.' + wp.editorTarget).length ) {
        $( '.' + wp.editorTarget + ' table' ).each(function() {
          $(this).wrap('<div class="' + wp.tableClass + '"><div class="' + wp.tableInnerClass + '"></div></div>');
        });
        $( '.' + wp.editorTarget + ' iframe' ).each(function() {
          $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
        });
      }
      // if ( $('.' + wp.editorTarget).length ) {
      //   $( '.' + wp.editorTarget + ' table' ).each(function() {
      //     $(this).wrap('<div class="' + wp.tableClass + '"></div>');
      //   });
      //   $( '.' + wp.editorTarget + ' iframe' ).each(function() {
      //     $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
      //   });
      // }
    }

  }


  const Form = {
    init(){
      form ={
        targetClass  : "mw_wp_form",
        confirmClass : "mw_wp_form_confirm",
        errorClass   : "error",
      }
    },
    check(){
      if($('.'+form.targetClass).length){
        let shift    = $('.'+mod.targetMenu).outerHeight(),
            position = $('.'+form.targetClass).offset().top - shift;
        if($('.'+form.targetClass).hasClass(form.confirmClass) || $('.'+form.errorClass).length){
          $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
          return false;
        }
      }
    },
  }

  const Favorite = {
    init() {
      favorite = {
        targetClass : '.js-favorite',
      };

      this.check();
    },
    check() {
      if($(favorite.targetClass).length){
        $(favorite.targetClass).on('click',function(){
          $.cookie.json = true;
          if( $.cookie('favorite') ) {
            var cookie = $.cookie('favorite');
          } else {
            var cookie = [];
          }

          var clip_id  = Number( $(this).data('id') );

          if( $(this).hasClass('is-act') ) {
            $(this).removeClass('is-act');
            var idx = cookie.indexOf(clip_id);
            if(idx >= 0){
              cookie.splice(idx, 1);
            }
          } else {
            $(this).addClass('is-act');
            cookie.unshift(clip_id);
          }

          $.cookie('favorite', cookie, { expires: 30, path: "/" });
        });
      }
    }
  }


  const Slick = {

    init() {
    },

    topKey() {
      $('.js-key_slide').slick({
        fade:false,
        infinite: true,
        speed: 600,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        dots:true,
        centerMode: true,
        centerPadding: '8.5vw',
        responsive:[
          {
            breakpoint: website.breakpoint.tb,
            settings:{
              centerPadding:'4vw',
            }
          },
          {
            breakpoint: website.breakpoint.sp,
            settings:{
              centerPadding:'0',
            }
          },
        ]
      });
    },

    catImage(){
      $('.js-cat_slide').slick({
        fade:true,
        infinite: true,
        speed: 600,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        dots:false,
      });

      if(website.winWidth > website.breakpoint.tb){
        $('.js-cat_slide_trg').hover(function(){
          let idx = $(this).parent().index();
          $('.js-cat_slide').slick('slickGoTo', idx, false);
        });
      }
    },

    itemKey() {
      $('.js-item_slide').slick({
        fade:true,
        infinite: true,
        speed: 600,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        dots:true,
        appendDots: $('.js-item_slide_thumb'),
        customPaging: function(slick, index){
          return '<img src="'+ slick.$slides.eq(index).find('img').attr('src') +'" alt="'+ index +'"/>';
        },
      })
    }

  }

  // const Pagenavi = {
  //   init(){
  //     navi = {
  //       self: 'js-pagenavi',
  //     }
  //     this.check();
  //   },
  //   check(){
  //     let target={};
  //     if($('.' + navi.self).length){
  //       $($('.'+navi.self).get().reverse()).each(function(){
  //         let href = $(this).attr('href')
  //         target[href] = $(href).offset().top;
  //         if($(href).offset().top - website.winHeight/2 < website.scrollY){
  //           $(this).parent('li').siblings('li').children('.'+navi.self).removeClass('is-act');
  //           $(this).addClass('is-act');
  //           return false;
  //         }
  //       });
  //     }
  //   }
  // }

  const Top = {

    init() {
      Slick.topKey();
      Slick.catImage();
    },

    update() {
    },

  }

  const ItemPage = {
    init(){
      Slick.itemKey();
    },

    update() {
    },
  }

  const BasicPage = {

    init() {
    },

    update() {
    },

  }


  const App = {

    init() {
      this.set();
      // this.update();
    },

    set() {
      Modules.init();
      DrawerMenu.init();
      // PageScroll.init();
      Wordpress.init();
      Favorite.init();
      if ( website.page == 'top' ) {
        Top.init();
      } else if(website.page == 'item_single') {
        ItemPage.init();
      }


      WinLoad.add( Animation.check );
      WinLoad.add( Modules.fixedMenu );

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( Animation.check );
      WinScroll.add( Modules.fixedMenu );


      WinLoad.init();
      WinResize.init();
      WinScroll.init();
    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.decoImg();
    //     }
    //   }
    //   // else {
    //   //   PageScroll.set();
    //   // }
    // },

  }


  App.init();

})();


})(jQuery);